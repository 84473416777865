<template>
  <div id="CancelPay">
    <main class="main-box">
      <div class="main">
        <h3>当前状态</h3>
        <div class="top">
          <button>已取消</button>
          <span style="font-size: 13px;">
            您的订单完成；如果您想开具发票，可以
            <a href="#">重新购买</a>
          </span>
        </div>
        <hr />
        <h3 style="margin-top: 30px;">课程信息</h3>
        <div class="content">
          <img src="#" alt="" />
          <br />
          <p>课程名称课程名称课程名称课程名称课程名称</p>
          <br />
          <br />
          <br />
          <br />
          <p class="price">
            ￥
            <span>100.00</span>
          </p>
        </div>
        <br />
        <br />
        <br />
        <div class="total">
          <p>需支付金额</p>
          <p>
            课程金额
            <span>
              ￥
              <span>100.00</span>
            </span>
          </p>
          <p>
            应付金额
            <span class="color">
              ￥
              <span>100.00</span>
            </span>
          </p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'CancelPay',
};
</script>

<style lang="scss" scoped>
.main-box {
  width: 1200px;
  height: 80vh;
  margin: auto;
  .top {
    margin-bottom: 30px;
    button {
      width: 200px;
      height: 60px;
      background: transparent;
      border: transparent;
      background: #f7b515;
      outline: none;
      border-radius: 5px;
      color: #ffffff;
      margin: 30px 40px 30px 0;
    }
  }
  .main {
    height: 80vh;
    padding: 60px 30px;
  }
  .content {
    margin: 30px 0px;
    img {
      width: 400px;
      height: 200px;
      margin-right: 30px;
      float: left;
    }
    .price {
      font-size: 30px;
      color: #f7b515;
    }
  }
}
</style>
